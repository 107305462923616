import React from 'react';

import { PAGES } from '../../../utils/utils';
import SeparationDivorce from '../../../components/en/expertise/separation-divorce';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';

const SeparationDivorcePage = () => (
  <Layout lang="en" page={PAGES.COMPETENCES_SEPARATION_DIVORCE}>
    <SEO title="Separation-Divorce" lang="en" />
    <SeparationDivorce />
  </Layout>
);

export default SeparationDivorcePage;
