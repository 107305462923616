import React from 'react';

import { getYearsSinceOfficeBirth } from '../../../utils/utils';
import ExpertiseContainer, { EXPERTISE, generateBreadcrumb } from './container';

const SeparationDivorce = () => {
  return (
    <ExpertiseContainer currentOption={EXPERTISE.SEPARATION_DIVORCE}>
      <h1>Separation-Divorce</h1>

      {generateBreadcrumb('separation-divorce')}

      <hr />

      {/* Description */}

      <div id="texteImg">
        <img
          className="divorce"
          src="/img/competences_sprite.jpg"
          alt="Separation-Divorce"
        />
      </div>

      <p>
        A <span className="accent">separation</span> or a{' '}
        <span className="accent">divorce</span> is often a major upheaval in the
        lives of those involved and brings a lot of questions : What will happen
        about <span className="accent2">child custody</span>? Who will provide
        for their needs and to what amount? What to do with the{' '}
        <span className="accent2">family residence</span>? Who will pay the
        family debts? What are my rights in regard to{' '}
        <span className="accent2">support</span>?
      </p>
      <p>
        Our experienced lawyers, among which one has{' '}
        <span className="accent">
          <span id="anneesExp">{getYearsSinceOfficeBirth()}</span> years of
          experience
        </span>{' '}
        in the field, will guide you through the conflict you are facing and
        will work effectively with you to find the best solutions for you with
        professionalism in a listening environment.
      </p>
    </ExpertiseContainer>
  );
};

SeparationDivorce.propTypes = {};

export default SeparationDivorce;
